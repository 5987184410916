/**
 * Location where users may interact with a card (whether it be a trail/maps/activity/etc card). Each value speicifies the tab first, followed by the view within that tab.
 */
enum CardLocation {
  ActivityDetails = 'activity details',
  CommunityTabFeedView = 'community tab feed view',
  CommunityTabIndividualFirstPartyPostView = 'community tab individual first party post view',
  CommunityTabIndividualThirdPartyPostView = 'community tab individual third party post view',
  CommunityTabLocationFeedView = 'community tab location feed view',
  CommunityTabMyPostsView = 'community tab my posts view',
  ExploreTabHomepageView = 'explore tab homepage view',
  ExploreTabListView = 'explore tab list view',
  ExploreTabListViewParkMode = 'explore tab list view park mode',
  ExploreTabMapView = 'explore tab map view',
  ExploreTabMapViewParkMode = 'explore tab map view park mode',
  ExploreTabTrailDetailsView = 'explore tab trail details view',
  FirstPartyActivitiesMapView = 'first party activities map view',
  FirstPartyActivitiesView = 'first party activities view',
  FirstPartyCompletedMapView = 'first party completed map view',
  FirstPartyCompletedView = 'first party completed view',
  FirstPartyMapsMapView = 'first party maps map view',
  FirstPartyMapsView = 'first party maps view',
  FirstPartyStatsHighlights = 'first party stats highlights',
  FirstPartyStatsProgressGraph = 'first party stats progress graph',
  FirstPartyStatsView = 'first party stats view',
  FirstPartyStatsViewMapDetails = 'first party stats view map details',
  Guide = 'guide',
  HighlightsCarousel = 'highlights carousel',
  LiveShareTrackingPage = 'live share tracking page',
  LocationPage = 'location page',
  MapDetails = 'map details',
  NavigatorRoutePickerLists = 'navigator route picker lists',
  NavigatorRoutePickerNearbyTrails = 'navigator route picker nearby trails',
  NearbyTrailCard = 'nearby trail card',
  ParkPage = 'park page',
  ParksPageGallery = 'parks page gallery',
  ParksPagePopularTrails = 'parks page popular trails',
  PoiPage = 'poi page',
  ProgressBarGraph = 'progress bar graph',
  SavedTabActivitiesMapView = 'saved tab activities map view',
  SavedTabActivitiesView = 'saved tab activities view',
  SavedTabCompletedMapView = 'saved tab completed map view',
  SavedTabCompletedView = 'saved tab completed view',
  SavedTabCustomMapsMapView = 'saved tab custom maps map view',
  SavedTabCustomMapsView = 'saved tab custom maps view',
  SavedTabDownloadsMapView = 'saved tab downloads map view',
  SavedTabDownloadsView = 'saved tab downloads view',
  SavedTabListsMapView = 'saved tab lists map view',
  SavedTabListsView = 'saved tab lists view',
  SavedTabParksListsView = 'saved tab parks lists view',
  SavedTabParksMapView = 'saved tab parks map view',
  ThirdPartyActivitiesMapView = 'third party activities map view',
  ThirdPartyActivitiesView = 'third party activities view',
  ThirdPartyCompletedMapView = 'third party completed map view',
  ThirdPartyCompletedView = 'third party completed view',
  ThirdPartyListsMapView = 'third party lists map view',
  ThirdPartyListsView = 'third party lists view',
  ThirdPartyMapsMapView = 'third party maps map view',
  ThirdPartyMapsView = 'third party maps view',
  ThirdPartyProfilePosts = 'third party profile posts',
  ThirdPartyStatsView = 'third party stats view',
  TrailBanner = 'trail banner',
  TrailDetailReviewShowActivity = 'trail detail review show activity',
  TrailDetails = 'trail details',
  TrailDetailsActivitiesView = 'trail details activities view',
  WebHomepageRiver = 'web homepage river',
  WebTrailPageTopModule = 'web trail page top module'
}

export default CardLocation;
