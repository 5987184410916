enum MapType {
  Activity = 'activity',
  Cloned = 'cloned',
  Custom = 'custom',
  Map = 'map',
  Park = 'park',
  Trail = 'trail'
}

export default MapType;
