/**
 * Locations where users may interact with a trail card (note, these cards may contain maps/activites/etc).
 */
enum TrailCardLocation {
  POIPage = 'POI page',
  CommunityFeed = 'community feed',
  CommunityMyPosts = 'community my posts',
  ExploreListView = 'explore list view',
  ExploreMapView = 'explore map view',
  ExploreTab = 'explore tab',
  LiveShareTrackingPage = 'live share tracking page',
  ParksPage = 'parks page',
  SavedActivities = 'saved activities',
  SavedCompleted = 'saved completed',
  SavedDownloads = 'saved downloads',
  SavedLists = 'saved lists',
  SavedParks = 'saved parks',
  Seo = 'seo',
  WebTrailPage = 'web trail page'
}

export default TrailCardLocation;
