/**
 * The location of a map when it is interacted with, such as printing. (e.g. explore map page, trail map page, activity/recording page, saved map.
 */
enum MapLocation {
  Activity = 'activity',
  AddCustomMap = 'add custom map',
  Community = 'community',
  Contribute = 'contribute',
  Explore = 'explore',
  Guide = 'guide',
  LocationPage = 'location page',
  Navigator = 'navigator',
  ParkList = 'park list',
  ParkPage = 'park page',
  SavedMap = 'saved map',
  SeoPage = 'seo page',
  Trail = 'trail',
  TrailsManagePage = 'trails manage page',
  UserActivities = 'user activities',
  UserLists = 'user lists',
  UserMaps = 'user maps',
  WebHomepage = 'web homepage'
}

export default MapLocation;
