import CardLocation from '../enums/CardLocation';
import GallerySection from '../enums/GallerySection';
import TapFeature from '../enums/TapFeature';
import TrailCardLocation from '../enums/TrailCardLocation';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logTrailCardClickedParams = {
  card_location?: TrailCardLocation;
  detailed_card_location?: CardLocation;
  gallerysection?: GallerySection;
  park_id?: string;
  photo_id?: number;
  river_filter_set?: string;
  river_index?: number;
  river_item_index?: number;
  river_section_type?: string;
  river_title?: string;
  river_title_filter?: string[];
  tap_feature?: TapFeature;
  trail_id: string;
};

/**
 * trail card was clicked by the user
 * @param params - Attributes to log with the amplitude event
 * @param params.card_location - The card location where the trail card was tapped.
 * @param params.detailed_card_location - Detailed location in the app where the trail card was clicked.
 * @param params.gallerysection -  The parent (tour, top, latest, seasons, sights, trails)  or child (sights and attractions) section from which the card clicked occured if click occurred in the gallery.
 * @param params.park_id - id of the park that the trail belongs to, if applicable, that was clicked on by the user
 * @param params.photo_id - The photo id of the photo present on the card at the time of clicking.
 * @param params.river_filter_set - [Homepage river card] The full filter JSON used to generate the river
 * @param params.river_index - [Homepage river card] The zero-indexed position of the river on the homepage.
 * @param params.river_item_index - [Homepage river card] The zero-indexed position of the item in the homepage river.
 * @param params.river_section_type - [Homepage river card] The type of river selected. Should be one of the following ('generic_trail_list', 'personalized_trail_list').
 * @param params.river_title - [Homepage river card] The title of the homepage river in the applications source language. This means ES users will have Spanish river titles, FR users French, etc.
 * @param params.river_title_filter - [Homepage river card] The specific filter value(s) used to generate the river
 * @param params.tap_feature - The component of the trail card that a user clicks to enter into a trail page. Initially enabled for trail card improvement experiment in Feb 2024, with two possible values of card and static map. Additionally values added in July 2024.
 * @param params.trail_id - id of the trail that was clicked by the user
 */
const logTrailCardClicked = (
  {
    card_location,
    detailed_card_location,
    gallerysection,
    park_id,
    photo_id,
    river_filter_set,
    river_index,
    river_item_index,
    river_section_type,
    river_title,
    river_title_filter,
    tap_feature,
    trail_id
  }: logTrailCardClickedParams,
  callback?: () => void
) => {
  getAmplitudeInstance().logEvent(
    'trail card clicked',
    {
      'card location': card_location,
      'detailed card location': detailed_card_location,
      gallerysection,
      'park id': park_id,
      'photo id': photo_id,
      'river filter set': river_filter_set,
      'river index': river_index,
      'river item index': river_item_index,
      'river section type': river_section_type,
      'river title': river_title,
      'river title filter': river_title_filter,
      'tap feature': tap_feature,
      'trail id': trail_id
    },
    callback
  );
};

export default logTrailCardClicked;
