import CardLocation from '../enums/CardLocation';
import MapLocation from '../enums/MapLocation';
import MapType from '../enums/MapType';
import { getAmplitudeInstance } from '../amplitudeHelpers';

type logSaveClickedParams = {
  detailed_card_location?: CardLocation;
  map_id: number;
  map_type: MapType;
  park_id?: string;
  save_location?: MapLocation;
  trail_id?: number;
};

/**
 * A user clicks the save 'bookmark' icon to save a trail, prompting the list selection to pop-up.
 * @param params - Attributes to log with the amplitude event
 * @param params.detailed_card_location - Detailed location in the app where the save action occurred.
 * @param params.map_id - The id of the map (could be trail/activity/etc)
 * @param params.map_type - The type of map where the icon was clicked.
 * @param params.park_id - id of the park that the trail belongs to, if applicable, that was saved by the user
 * @param params.save_location - The screen on which the user was viewing an item when they clicked to save the map or trail to a list.
 * @param params.trail_id - The id of the trail
 */
const logSaveClicked = (
  { detailed_card_location, map_id, map_type, park_id, save_location, trail_id }: logSaveClickedParams,
  callback?: () => void
) => {
  getAmplitudeInstance().logEvent(
    'save clicked',
    {
      'detailed card location': detailed_card_location,
      'map id': map_id,
      'map type': map_type,
      'park id': park_id,
      'save location': save_location,
      'trail id': trail_id
    },
    callback
  );
};

export default logSaveClicked;
